import React from "react";
import CustomTable from "../../../customComponents/reactTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getCandidateInvitedJobs,
  getCandidateMatchingJobs,
  getClientDashboardCount,
  getJobDescription,
  postClientInviteMultipleJob,
} from "../../ClientSlice";
import { useState } from "react";
import { log_debug } from "../../../apiHelpers/logger";
import { Tags } from "../../../pages/Contacts/ContactList/contactlistCol";
import { Badge, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FiInbox } from "react-icons/fi";
import Preloader from "../../../components/Common/Preloader";
import JobOverview from "../../../customComponents/offcanvas/JobOverviewCanvas";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

function MatchingJobs({
  data,
  status,
  individualguid,
  RecordCount,
  currentPage,
  handlePageChange,
}) {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [JobDescription, setJobDescription] = useState([]);
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    log_debug("recieved table individual guid", data);
  }, [,data]);

  const jobdescription = (guid) => {
    setloading(true);
    dispatch(getJobDescription(guid)).then((response) => {
      if (response.payload?.success === true) {
        setJobDescription(response.payload?.data?.jobdescription);
        setloading(false);
        setOffcanvasOpen(true);
      }
    });
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  const handleButtonClick = (data) => {
    log_debug("invite data", data);
    setloading(true);
    dispatch(
      postClientInviteMultipleJob({
        formdata: {
          individual_guid: [individualguid],
          job_guids: data.original.guid,
        },
      }),
    ).then((response) => {
      if (response.payload?.success === true) {
        dispatch(
          getCandidateMatchingJobs({
            guid: individualguid,
            currentPage: 0,
          }),
        );

        setTimeout(() => {
          dispatch(
            getCandidateInvitedJobs({
              guid: individualguid,
              currentPage: 0,
            }),
          );
        }, 2000);
        dispatch(
          getClientDashboardCount({
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
          }),
        );
        setloading(false);
        toast.success("Successfully Invited the Candidate", {
          position: "top-right",
          autoClose: 4000, // Close the toast after 5 seconds
          // hideProgressBar: true,
        });
      }
    });
  };

  const columns = [
    {
      Header: "Job Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            jobdescription(row.original.guid);
          }}
        >
          {row.values.title}
        </Link>
      ),
      //   Cell: ({ row }) => (
      //     <Link
      //       to={"#"}
      //       onClick={() => {
      //         Job(row.original);
      //       }}
      //     >
      //       {row.values.title}
      //     </Link>
      //   ),
    },
    // {
    //   Header: "Status",
    //   accessor: "status",
    // },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ value }) => value.join(", "),
    },
    {
      Header: "Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },
    {
      Header: "Created on",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <Badge
          color={
            row.values.status === "active"
              ? "success text-white rounded-pill"
              : "warning text-white rounded-pill"
          }
        >
          {row.values.status}
        </Badge>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <>
          <Link to={"#"} className="text-white">
            {/* <Button
              color="primary"
              variant="primary"
              className=""
              id="applyMultipleJobButton"
              onClick={() => {
                candidateName2(row.original);
                
              }}
            >
              Apply
            </Button> */}
            <Button
              color="primary"
              className="px-1 "
              // color={appliedRows.includes(row.id) ? "success" : "primary"}
              // disabled={appliedRows.includes(row.id)}
              onClick={() => handleButtonClick(row)}
            >
              {/* <FiInbox /> */}
              <i className="mdi mdi-email-send mx-1 btn-lg"></i>
              Invite &nbsp;
              {/* {appliedRows.includes(row.id) ? "Applied" : "Apply"} */}
            </Button>
          </Link>
        </>
      ),
    },
    // {
    //   Header: "Relevance",
    //   accessor: "score",
    // },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  return (
    <>
      {loading && <Preloader />}
      <CustomTable
        tableDatas={data}
        headerData={[]}
        columns={columns}
        data={data}
        multipleInviteJobs={status != "contracted" ? true : false}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        //hideCheckbox={true}
        totalCount={RecordCount}
        individualguid={individualguid}
      />

      {isOffcanvasOpen && (
        <JobOverview
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={JobDescription}
        />
      )}

      <ToastContainer />
    </>
  );
}

export default MatchingJobs;
