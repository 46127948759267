import React from "react";
import { log_debug } from "../../apiHelpers/logger";

const FunnelProgressBar = ({ candidatureHistory , interviewStatus}) => {
  log_debug("candidatehistory9080",interviewStatus?.status)
  const status = interviewStatus && interviewStatus?.status;
  return (
    //Previous code by pravesh
    // <div className="hstack">
    //   <a
    //     href="#"
    //     className={`funnel ${
    //       candidatureHistory.length > 0 &&
    //       candidatureHistory &&
    //       candidatureHistory[0].status &&
    //       candidatureHistory[0].status.includes("rejected") &&
    //       candidatureHistory[0].status.includes("new")
    //         ? "bg-danger"
    //         : candidatureHistory.length > 0 &&
    //             candidatureHistory &&
    //             candidatureHistory[0].status &&
    //             candidatureHistory[0].status.includes("new") &&
    //             !candidatureHistory[0].status.includes("rejected")
    //           ? "bg-primary"
    //           : ""
    //     }`}
    //   >
    //     Screen Select
    //   </a>

    //   <a
    //     href="#"
    //     className={`funnel ${
    //       candidatureHistory.length > 0 &&
    //       candidatureHistory &&
    //       candidatureHistory[0].status &&
    //       candidatureHistory[0].status.includes("rejected") &&
    //       candidatureHistory[0].status.includes("l1")
    //         ? "bg-danger"
    //         : candidatureHistory.length > 0 &&
    //             candidatureHistory &&
    //             candidatureHistory[0].status &&
    //             candidatureHistory[0].status.includes("l1") &&
    //             !candidatureHistory[0].status.includes("rejected")
    //           ? "bg-primary"
    //           : ""
    //     }`}
    //   >
    //     L1 Interview
    //   </a>

    //   <a
    //     href="#"
    //     className={`funnel ${
    //       candidatureHistory.length > 0 &&
    //       candidatureHistory &&
    //       candidatureHistory[0].status &&
    //       candidatureHistory[0].status.includes("rejected") &&
    //       candidatureHistory[0].status.includes("l2")
    //         ? "bg-danger"
    //         : candidatureHistory.length > 0 &&
    //             candidatureHistory &&
    //             candidatureHistory[0].status &&
    //             candidatureHistory[0].status.includes("l2") &&
    //             !candidatureHistory[0].status.includes("rejected")
    //           ? "bg-primary"
    //           : ""
    //     }`}
    //   >
    //     L2 Interview
    //   </a>

    //   <a
    //     href="#"
    //     className={`funnel ${
    //       candidatureHistory.length > 0 &&
    //       candidatureHistory &&
    //       candidatureHistory[0].status &&
    //       candidatureHistory[0].status.includes("rejected") &&
    //       candidatureHistory[0].status.includes("offer")
    //         ? "bg-danger"
    //         : candidatureHistory.length > 0 &&
    //             candidatureHistory &&
    //             candidatureHistory[0].status &&
    //             candidatureHistory[0].status.includes("offer") &&
    //             !candidatureHistory[0].status.includes("rejected")
    //           ? "bg-primary"
    //           : ""
    //     }`}
    //   >
    //     {" "}
    //     Offer{" "}
    //   </a>

    //   <a
    //     href="#"
    //     className={`funnel ${
    //       candidatureHistory.length > 0 &&
    //       candidatureHistory[0].status &&
    //       candidatureHistory[0].status.includes("contract") &&
    //       candidatureHistory[0].status.includes("rejected")
    //         ? "bg-danger"
    //         : candidatureHistory.length > 0 &&
    //             candidatureHistory &&
    //             candidatureHistory[0].status &&
    //             candidatureHistory[0].status.includes("contract") &&
    //             !candidatureHistory[0].status.includes("rejected")
    //           ? "bg-primary"
    //           : ""
    //     }`}
    //   >
    //     {" "}
    //     Contract{" "}
    //   </a>
    // </div>

    <div className="hstack">
    <a
      href="#"
      className={`funnel ${
        (status.includes("L1") || status.includes("L2") || status.includes("HR"))&&(status.includes("Scheduled")||status.includes("Rejected")||status.includes("Passed"))
          ? "bg-success"
          : status.includes("Screen Select") && status.includes("Scheduled")
          ? "bg-primary" 
          : status.includes("Screen Select") && status.includes("Rejected")?"bg-danger":""
      }`}
    >
      Screen Select
    </a>

    <a
      href="#"
      className={`funnel ${
        (status.includes("L2") || status.includes("HR"))&&(status.includes("Scheduled")||status.includes("Rejected")||status.includes("Passed"))
          ? "bg-success"
          : status.includes("L1") && status.includes("Scheduled")
          ? "bg-primary"
          : status.includes("L1") && status.includes("Rejected")?"bg-danger":""
      }`}
    >
      L1 Interview
    </a>

    <a
      href="#"
      className={`funnel ${
        status.includes("HR")&&(status.includes("Scheduled")||status.includes("Rejected")||status.includes("Passed"))
          ? "bg-success"
          : status.includes("L2") && status.includes("Scheduled")
          ? "bg-primary"
          : status.includes("L2") && status.includes("Rejected")?"bg-danger":""
      }`}
    >
      L2 Interview
    </a>

    <a
      href="#"
      className={`funnel ${
        status.includes("HR")
          ? "bg-success"
          : ""
      }`}
    >
      Final Select
    </a>
  </div>
  );
};

export default FunnelProgressBar;
