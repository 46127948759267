import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import DashboardCountCard from "../customComponents/DashboardCountCard";
import ApexChart from "../customComponents/charts/ApexChart";
import Breadcrumb from "../components/Common/Breadcrumb";
import { DateRangePicker } from "react-date-range";
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment/moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { log_debug } from "../apiHelpers/logger";
import { useDispatch, useSelector } from "react-redux";
import { getGraphCount, getJobsCount } from "./individualSlice";
import { AuthContext } from "../App";
import IndividualDashboardTabs from "./Components/DashboardComponents/DashboardTabs";
import { useNavigate } from "react-router-dom";
const userType = "individual";

const IndividualDashboard = () => {
  document.title = "Company Bench";

  const defaultStartDate = new Date();

  const defaultEndDate = new Date();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    key: "selection",
  });

  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );
  const individualStore = useSelector((state) => state.individualSlice);
  const dispatch = useDispatch();
  const [dashboardData, setdashboardData] = useState([]);
  const [graphData, setgraphData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    log_debug("Dashboard local storage status", localStorage.getItem("Iguid"));
  }, []);

  // useEffect(() => {
  //   dispatch(
  //     getJobsCount({
  //       fromDate: formattedStartDate,
  //       toDate: formattedEndDate,
  //     }),
  //   );

  //   dispatch(
  //     getGraphCount({
  //       fromDate: formattedStartDate,
  //       toDate: formattedEndDate,
  //     }),
  //   );
  // }, []);

  useEffect(() => {
    dispatch(
      getJobsCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );

    dispatch(
      getGraphCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, [formattedEndDate]);

  // useEffect(() => {
  //   dispatch(getJobsCount({
  //     from:formattedStartDate,
  //     to:formattedEndDate
  //   }))
  // }, []);

  const datePickerStyle = {
    position: "absolute",
    right: "0%",
    boxShadow: "0px 0px 8px 0px grey",
    zIndex: "1",
    backgroundColor: "#fff",
    padding: "5px",
    borderRadius: "8px",
  };

  const handleButtonClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    setformattedStartDate(
      moment(ranges.selection.startDate).format("YYYY-MM-DD"),
    );

    setformattedEndDate(moment(ranges.selection.endDate).format("YYYY-MM-DD"));

    log_debug("handle select date", formattedEndDate);
  };

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (individualStore.data.JobsCount?.success === true) {
        setdashboardData(
          individualStore.data.JobsCount?.data?.dashboard_counts,
        );
      }

      if (individualStore.data.GraphCount?.success === true) {
        setgraphData(individualStore.data.GraphCount?.data?.dashboard_counts);
      }

      if (individualStore.data.GraphCount?.success === true) {
        setgraphData(individualStore.data.GraphCount?.data?.dashboard_counts);
      }
    }
  }, [individualStore.data.JobsCount, individualStore.data.GraphCount]);

  let ids = [];
  let applied_jobs_counts = [];
  let selected_interview_counts = [];
  let accepted_offer_counts = [];

  // Loop through the dashboard_counts array
  graphData.forEach((item) => {
    const formattedDate = moment(item._id).format("YYYY-MM-DD");
    ids.push(formattedDate);
    applied_jobs_counts.push(item.applied_jobs_count);
    selected_interview_counts.push(item.selected_interview_count);
    accepted_offer_counts.push(item.accepted_Offer_count);
  });

  // Print out the arrays

  const pieseries = [44, 55, 41, 17, 15];
  const pieoptions = {
    labels: ["Passed", "Rejected", "Failed", "Cancelled", "Scheduled"],
    colors: ["#5156be", "#bcbfff", "#777aca", "#8085ea", "#a8aada"],
    // colors: ["#2ab57d", "#5156be", "#fd625e", "#4ba6ef", "#ffbf53"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: 4,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };
  const series = [
    {
      name: "Applied Jobs",
      data: applied_jobs_counts,
    },
    {
      name: "Interviews",
      data: selected_interview_counts,
    },
    {
      name: "Offers",
      data: accepted_offer_counts,
    },
  ];
  const apexoptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#52A9CC", "#0036E1", "#2FB6EB"],
    xaxis: {
      categories: ids,
      title: {
        text: "Date",
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Math.round(value); // Rounds the value to an integer
        },
      },
      title: {
        text: "Number of Jobs",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " Jobs";
        },
      },
    },
  };
  const options = {
    chart: {
      height: 50,
      type: "line",
      toolbar: { show: false },
      sparkline: {
        enabled: true,
      },
    },
    colors: ["#5156be"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };

  const { applied_jobs, interviews, offers, tiq_score } = dashboardData;

  const AppliedJobsData = {
    title: "Applied Jobs",
    value: applied_jobs?.total_applied_jobs || "-",
    type: "line",
    //sinceLastWeek: "Since last week",
    // series: [/* Your series data for the chart */],
    series: [],
    statusColor: "success", // Change this to your desired color
    // rank: `${-20} Jobs`, // Provide a rank value
  };

  const Interviews = {
    title: "Interviews",
    value: interviews?.total_interviews || "=",
    type: "line",
    //sinceLastWeek: "Since last week",
    // series: [/* Your series data for the chart */],
    series: [],
    statusColor: "success", // Change this to your desired color
    // rank: `${-20} Jobs`, // Provide a rank value
  };

  const TalentIQData = {
    title: "TIQ Score",
    value:
      tiq_score?.tiq_assessment_score === "No assessment"
        ? 0
        : tiq_score?.tiq_assessment_score,
    type: "line",
    // sinceLastWeek: "Since last week",
    series: [
      /* Your series data for the chart */
    ],
    //series: [],
    statusColor: "success", // Change this to your desired color
    rank: `Latest TIQ score`, // Provide a rank value
  };

  const Offers = {
    title: "Offers",
    value: offers?.total_offers || "-",
    type: "line",
    //sinceLastWeek: "Since last week",
    series: [
      /* Your series data for the chart */
    ],
    //series: [],
    statusColor: "success", // Change this to your desired color
    // rank: `${-20} Jobs`, // Provide a rank value
  };

  function formatDate(date) {
    // Example: YYYY-MM-DD
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .split("/")
      .reverse()
      .join("-");

    // If the formatted date is in the YYYY-DD-MM format, split it and rearrange it to YYYY-MM-DD
    const parts = formattedDate.split("-");
    return `${parts[0]}-${parts[2]}-${parts[1]}`;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col>
              <Breadcrumb
                title="Home"
                breadcrumbItem="Dashboard"
                Name="Hi User"
              />
            </Col>
            <Col lg={3}>
              <div>
                <form className="app-search d-none d-lg-block">
                  <div className="position-relative">
                    <div>
                      <input
                        type="text"
                        className="form-control  text text-center bg-light"
                        // placeholder={dateRange}
                        value={`${formatDate(
                          selectionRange.startDate,
                        )}${" "} to ${" "}${formatDate(
                          selectionRange.endDate,
                        )}`}
                        style={{ cursor: "pointer" }}
                        onClick={handleButtonClick}
                        readOnly
                      />

                      <IconButton
                        className="btn"
                        color="primary"
                        onClick={handleButtonClick}
                      >
                        <i className="align-middle">
                          <CalendarMonthIcon />
                        </i>
                      </IconButton>
                    </div>
                    {/* </button> */}
                  </div>
                </form>

                {showDatePicker && (
                  <div style={datePickerStyle}>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                      maxDate={new Date()}
                    />
                  </div>
                )}
              </div>
            </Col>
            {/* <Col lg={3}>
              <div>
                <form className="app-search d-none d-lg-block">
                  <div className="position-relative">
                    <div>
                      <input
                        type="text"
                        className="form-control  text text-center bg-light"
                        // placeholder={dateRange}
                        value={`${selectionRange.startDate.toLocaleDateString()} - ${selectionRange.endDate.toLocaleDateString()}`}
                        style={{ cursor: "pointer" }}
                        onClick={handleButtonClick}
                        readOnly
                      />

                      <IconButton
                        className="btn"
                        color="primary"
                        onClick={handleButtonClick}
                      >
                        <i className="align-middle">
                          <CalendarMonthIcon />
                        </i>
                      </IconButton>
                    </div>
                    {/* </button> 
                  </div>
                </form>

                {showDatePicker && (
                  <div style={datePickerStyle}>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </div>
                )}
              </div>
            </Col> */}
            {/* <p>Todo...</p> */}
          </Row>

          {/* <DashboardCountCard /> */}

          <Row>
            <Col
              xl={3}
              md={3}
              sm={6}
              onClick={() => navigate("/individual-applied")}
            >
              <DashboardCountCard
                title={AppliedJobsData.title}
                CircularProgress={0}
                options={options}
                hideCircularBar={true}
                {...AppliedJobsData}
              />
            </Col>
            <Col
              xl={3}
              md={3}
              sm={6}
              onClick={() => navigate("/individual-interviews")}
            >
              <DashboardCountCard
                title={Interviews.title}
                CircularProgress={0}
                options={options}
                hideCircularBar={true}
                {...Interviews}
              />
            </Col>
            <Col
              xl={3}
              md={3}
              sm={6}
              onClick={() => navigate("/individual-offers")}
            >
              <DashboardCountCard
                CircularProgress={0}
                value={Offers.value}
                title={Offers.title}
                hideCircularBar={true}
                options={options}
                {...Offers}
              />
            </Col>
            {/* <Col xl={3} md={3} sm={6}>
              <DashboardCountCard
                CircularProgress={TalentIQData.value * 10}
                title={TalentIQData.title}
                options={options}
                hideCircularBar={true}
                decimal={1}
                {...TalentIQData}
              />
            </Col> */}
          </Row>
          {graphData && graphData.length != 0 && (
            <Row>
              <Col xl={12} className="">
                <ApexChart
                  options={apexoptions}
                  series={series}
                  userType={userType}
                />
              </Col>
              {/* <Col xl={5}>
              <PieChart options={pieoptions} series={pieseries} />
            </Col>  */}
            </Row>
          )}
          <Card className="p-2">
            <Row>
              {/* <DashboardNavTabs /> */}
              <IndividualDashboardTabs
                startdate={formattedStartDate}
                enddate={formattedEndDate}
              />
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IndividualDashboard;
