import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

// Reactstrap
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Modal,
  ModalBody,
  Alert,
} from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LightDark from "../CommonForBoth/Menus/LightDark";

// import images
import logoSvg from "../../assets/images/logo-sm.svg";
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

//i18n
import { withTranslation } from "react-i18next";
//redux
import { useSelector, useDispatch } from "react-redux";

// Redux Store
// import {
//   showRightSidebarAction,
//   toggleLeftmenu,
//   changeSidebarType,
//   changelayoutMode
// } from "../../store/actions";
import { createSelector } from "reselect";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SendInvitation } from "../../Partner/PartnerSlice";
import { toast } from "react-toastify";
import { log_debug } from "../../apiHelpers/logger";

const Header = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  // const [SendInviteResponce, setSendInviteResponce] = useState("")

  const dispatch = useDispatch();

  const partnerStore = useSelector((state) => state.partnerSlice);
  // const dispatch = useDispatch();

  // const headerData = createSelector(
  //   (state) => state.Layout,
  //   (layout) => ({
  //     showRightSidebar: layout.showRightSide
  //   })
  // );
  // // Inside your component
  // const { showRightSidebar } = useSelector(headerData);

  // const { onChangeLayoutMode } = props;
  const [search, setsearch] = useState(false);
  // const [socialDrp, setsocialDrp] = useState(false);
  const [isClick, setClick] = useState(true);

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }

  const toggleModal = () => {
    setModalOpen(true);
  };

  const initialValues = {
    organizationType:
      localStorage.getItem("persona") === "Recruiting_Firm"
        ? "Recruiting Firm"
        : "IT Services",
    fullName: "",
    companyName: localStorage.getItem("UserCompanyName"),
    workEmail: "",
  };

  const validationSchema = Yup.object().shape({
    organizationType: Yup.string().required("Organization Type is required"),
    fullName: Yup.string().required("Full Name is required"),
    companyName: Yup.string().required("Company Name is required"),
    workEmail: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email is not valid",
      )
      .required("Email is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    try {
      // Handle form submission
      console.log("Form submitted:", values);
      dispatch(
        SendInvitation({
          full_name: values.fullName,
          email_id: values.workEmail,
          organisation_type: values.organizationType,
          company_name: values.companyName,
        }),
      ).then((response) => {
        if (response.payload.success === true) {
          resetForm();
          // toast.success(`Invitation send successfully!!`)
          toast.success(`Invitation sent successfully`);
          setModalOpen(false);
        } else if (response.payload.error === "Client Email_id") {
          resetForm();
          // toast.success(`Invitation send successfully!!`)
          toast.warning(`Email already associated with a Client`);
          setModalOpen(false);
        } else {
          resetForm();
          // toast.success(`Invitation send successfully!!`)
          toast.warning(`Email already exists`);
          setModalOpen(false);
        }
      });
    } catch (error) {
      console.log("error", error);
      resetForm();
      setModalOpen(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link
                to={
                  props.persona === "individual"
                    ? "/individualdashboard"
                    : props.persona === "partner"
                      ? "/dashboard"
                      : props.persona === "client"
                        ? "/client/dashboard"
                        : "#"
                }
                className="logo logo-dark"
              >
                <span className="logo-sm m-auto">
                  <img src={logoSvg} alt="" height="16" className="mt-1" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="16" className="mt-1" />
                  <span className="logo-txt font-size-22">companybench</span>
                </span>
              </Link>

              <Link
                to={
                  props.persona === "individual"
                    ? "/individualdashboard"
                    : props.persona === "partner"
                      ? "/dashboard"
                      : props.persona === "client"
                        ? "/client/dashboard"
                        : "#"
                }
                className="logo logo-light"
              >
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="16" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="16" />{" "}
                  <span className="logo-txt font-size-22">companybench</span>
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <button className="btn btn-primary" type="button">
                  <i className="bx bx-search-alt align-middle"></i>
                </button>
              </div>
            </form> */}
          </div>

          {/* <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FeatherIcon icon="search" className="icon-lg" />
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Search Result"
                      />

                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
          <div className="d-flex">
            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              {/* <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div> 
            </div> */}

            {/* <LanguageDropdown /> */}

            {/* light / dark mode */}
            {/* <LightDark layoutMode={props['layoutMode']} onChangeLayoutMode={onChangeLayoutMode} /> */}
            {/* 
            <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                tag="button"
              >
                <FeatherIcon
                  icon="grid"
                  className="icon-lg"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="p-2">
                  <Row className="g-0">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="g-0">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown> */}

            {/* <NotificationDropdown /> */}
            {/* <div
              // onClick={() => {
              //   dispatch(showRightSidebarAction(!showRightSidebar));
              // }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <FeatherIcon icon="settings" className="icon-lg" />
              </button>
            </div> */}
            {/* <form className="app-search d-none d-lg-block"> */}
            {/* {window.location.pathname === "/individualdashboard" && (
              <Alert color="primary" className="m-3">
                <i className="mdi mdi-alert-circle-outline align-middle me-2 fw-bold fs-5" />
                Please navigate to your{" "}
                <Link to="/individual-profile" className="alert-link">
                  profile
                </Link>{" "}
                and ensure that you have added the appropriate roles to your
                profile in order to receive matching jobs.
              </Alert>
            )} */}
            {/* {(localStorage.getItem("persona") === "Recruiting_Firm" ||
              localStorage.getItem("persona") === "partner") && (
              <div className="position-relative m-3">
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    toggleModal(e);
                  }}
                >
                  <strong>
                    <i className=" fas fa-paper-plane fs-6"></i>&nbsp;
                  </strong>{" "}
                  Send Invitation
                </button>
              </div>
            )} */}
            {/* </form> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          style={{ maxWidth: "40%" }}
        >
          <ModalBody className="py-3 px-5">
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  {/* <i
                    className="mdi mdi-alert-circle-outline"
                    style={{ fontSize: "9em", color: "orange" }}
                  /> */}
                  <h2 className="border-bottom py-2 text-primary">
                    {" "}
                    <strong>
                      <i className=" fas fa-paper-plane fs-7 "></i>&nbsp;
                    </strong>
                    Send Invitation
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-start mt-3">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="form-group col-12 mb-2">
                        <label htmlFor="organizationType">
                          Organization Type{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          id="organizationType"
                          name="organizationType"
                          type="text"
                          disabled
                          className={`form-control bg-light ${
                            formik.errors.organizationType &&
                            formik.touched.organizationType
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.organizationType}
                        />
                        {formik.errors.organizationType &&
                          formik.touched.organizationType && (
                            <div className="text-danger">
                              {formik.errors.organizationType}
                            </div>
                          )}
                      </div>
                      <div className="form-group col-12 mb-2">
                        <label htmlFor="companyName">
                          Company Name <span className="text-danger">*</span>
                        </label>

                        <input
                          id="companyName"
                          name="companyName"
                          type="text"
                          // disabled
                          className={`form-control bg-light ${
                            formik.errors.companyName &&
                            formik.touched.companyName
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyName}
                        />
                        {formik.errors.companyName &&
                          formik.touched.companyName && (
                            <div className="text-danger">
                              {formik.errors.companyName}
                            </div>
                          )}
                      </div>
                      <div className="form-group col-12 mb-2">
                        <label htmlFor="fullName">
                          Full Name <span className="text-danger">*</span>
                        </label>
                        <input
                          id="fullName"
                          name="fullName"
                          type="text"
                          className={`form-control bg-light ${
                            formik.errors.fullName && formik.touched.fullName
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.fullName}
                        />
                        {formik.errors.fullName && formik.touched.fullName && (
                          <div className="text-danger">
                            {formik.errors.fullName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12 mb-2">
                        <label htmlFor="workEmail">
                          Work Email <span className="text-danger">*</span>
                        </label>
                        <input
                          id="workEmail"
                          name="workEmail"
                          type="email"
                          className={`form-control bg-light ${
                            formik.errors.workEmail && formik.touched.workEmail
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.workEmail}
                        />
                        {formik.errors.workEmail &&
                          formik.touched.workEmail && (
                            <div className="text-danger">
                              {formik.errors.workEmail}
                            </div>
                          )}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary "
                      disabled={!formik.isValid || !formik.dirty}
                    >
                      Send Invitation
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger  mx-2"
                      onClick={() => setModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

// Header.propTypes = {
//   changeSidebarType: PropTypes.func,
//   leftMenu: PropTypes.any,
//   showRightSidebar: PropTypes.any,
//   showRightSidebarAction: PropTypes.func,
//   t: PropTypes.any,
//   toggleLeftmenu: PropTypes.func,
//   changelayoutMode: PropTypes.func,
//   layoutMode: PropTypes.any,
// };

// const mapStatetoProps = state => {
//   const {
//     layoutType,
//     showRightSidebar,
//     leftMenu,
//     layoutMode
//   } = state.Layout;
//   return { layoutType, showRightSidebar, leftMenu, layoutMode };
// };

// export default connect(mapStatetoProps, {
//   showRightSidebarAction,
//   changelayoutMode,
//   toggleLeftmenu,
//   changeSidebarType,
// })(withTranslation()(Header));

export default Header;
