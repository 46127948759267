import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardText,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DashboardCounter, addCandidateResume } from "../../Partner/PartnerSlice";
import dropzoneimage2 from "../../assets/images/companyBenchImage/icons8-upload-to-the-cloud.gif";
import { toast } from "react-toastify";
import moment from "moment";
import CustomBreadcrumb from "../breadcrumb/CustomBreadcrumb";
import Spinner from "../utilities/Spinner";
import { log_debug } from "../../apiHelpers/logger";
import { IndividualParseResumeapi } from "../../individual/individualSlice";

function IndividualParseResume() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [aggregateResponse, setAggregateResponse] = useState({});
  const [aggregateResponses, setAggregateResponses] = useState([]);
  const [individualResumes, setIndividualResumes] = useState([]);
  const [FileUploadAgain, setFileUploadAgain] = useState(false);
  const defaultEndDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 90);

  const [formattedStartDate, setformattedStartDate] = useState(
    // moment(defaultStartDate).format("YYYY-MM-DD"),
    moment(defaultStartDate).format("YYYY-MM-DD"),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dummyjson = [{
    candidates:{
        data:{
            resumes:[{
          full_name: "",
          date_of_birth: "",
          email_id: "",
          contact_no: "",
          address: "",
          work_experiences: [],
          educational_qualifications: [],
          certifications: [],
          linkedin_url: "",
          summary: "",
          resume_url: "",
          role: "",
          availability: "",
          monthly_salary: "",
          relevant_experience: "",
          prefered_locations: [],
          current_location: "",
          notice_period: "",
          experience: "",
          programming_languages: [],
            }]
        }
    }
  }]

  useEffect(() => {
    if (
      uploadedFiles.length === 4 &&
      uploadedFiles.every((file) => file.status === "success")
    ) {
      setUploading(false);
      const responses = uploadedFiles.reduce((acc, file) => {
        acc[file.name] = file.status;
        return acc;
      }, {});
      setAggregateResponse(responses);
      log_debug("All files uploaded successfully:", responses);
    }
  }, [uploadedFiles]);

 
  const uploadFilesSequentially = async (filteredFiles) => {
    setUploading(true);

    const allResponses = [];
    let successfulResponsesCount = 0;
    let unsuccessfulResponsesCount = 0;

    for (const file of filteredFiles) {
      try {
        log_debug("recieved add candidate file", file);
        const formData = new FormData();
        formData.append("resumes", file);

        try {
            const response = await dispatch(IndividualParseResumeapi(formData)).then((response) => {
                log_debug("parse successful",response)
                const fileResponse = {
                    fileName: file.name,
                    status: "success",
                    candidates : response?.payload,
                  };
                  allResponses.push(fileResponse);
                  successfulResponsesCount++;
            });
        } catch (error) {
            log_debug("error",error);
            toast.error("Something went wrong");

            navigate("/individual-details", {
                state: { individualResumes: dummyjson,individualSignup:true },
              });
        }
        

        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            name: file.name,
            status: "success",
          },
        ]);

        
      } catch (error) {
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            name: file.name,
            status: "error",
          },
        ]);

        const fileResponse = {
          fileName: file.name,
          status: "error",
          error: error.message,
        };
        allResponses.push(fileResponse);
        unsuccessfulResponsesCount++;
      }
    }


    setAggregateResponses(allResponses);


    if (
      // (allResponses.length >= filteredFiles.length) ||
      allResponses[0]?.candidates?.data?.resumes ||
      allResponses[1]?.candidates?.data?.resumes ||
      allResponses[2]?.candidates?.data?.resumes ||
      allResponses[3]?.candidates?.data?.resumes
    ) {
      setIndividualResumes(allResponses);
      dispatch(
        DashboardCounter({
          from_date: formattedStartDate,
          to_date: formattedEndDate,
        }),
      );
      log_debug("allresponsesdata40938",allResponses)
      navigate("/individual-details", {
        state: { individualResumes: allResponses, individualSignup:true },
      });
    } else {
      setFileUploadAgain(true);
      setUploading(false);
            toast.error("Something went wrong");
            setTimeout(() => {
                navigate("/individual-details", {
                    state: { individualResumes: dummyjson,individualSignup:true },
                  });
            }, 3000);
    }
  };

  const onDrop = async (acceptedFiles) => {
    setFileUploadAgain(true);
    const remainingSlots = 1 - uploadedFiles.length;
    const validFiles = acceptedFiles.slice(0, remainingSlots);

    const filteredFiles = validFiles.filter(
      (file) =>
        file.name.endsWith(".pdf") ||
        file.name.endsWith(".docx") ||
        file.name.endsWith(".doc"),
    );

    uploadFilesSequentially(filteredFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf, .docx, .doc",
  });

  const handelresumeckick = () => {
    // if (FileUploadAgain) {
    //   setUploading(!true);
    //   window.location.reload(); // Reload the page
    // }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div>
            <div className="col-lg-12">
              <Col className="col-lg-12 col-md-12 col-sm-12 ">
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card
                      style={{
                        width: "40%",
                        height: "500px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <CardHeader
                        className="text-center bg-primary-subtle font-size-22"
                        style={{ height: "100px" }}
                      >
                        <strong className="text-white">Upload</strong>
                        <span className="text-muted"> Resume</span>
                      </CardHeader>
                      <CardBody onClick={handelresumeckick}>
                        <CardText className="mb-0">
                          <div className="mt-5">
                            <div
                              {...getRootProps()}
                              className={` dropzone ${
                                isDragActive ? "active" : ""
                              }`}
                            >
                              <input {...getInputProps()} />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                {uploading ? (
                                  <Spinner />
                                ) : (
                                  <>
                                    <p style={{ marginBottom: "8px" }}>
                                      <strong>Drag and drop </strong> file here{" "}
                                      <br /> or <strong>browse</strong> to
                                      begin the upload. Accepted
                                      .pdf, .docx, .doc Only.
                                    </p>

                                    <img
                                      src={dropzoneimage2}
                                      style={{ width: "70px" }}
                                      alt="Dropzone Image"
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </CardText>
                        {/* <div className="py-4">
                          <p className=" bg-info  px-2 rounded py-3">
                            By uploading this resume, you confirm that you have
                            obtained the necessary consent from the candidate.
                            Please be assured that all information provided will
                            be used solely for recruitment purposes and will not
                            be shared or used elsewhere.
                          </p>
                        </div> */}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default IndividualParseResume;
