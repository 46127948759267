import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, useState } from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import images
import giftBox from "../assets/images/giftbox.png";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";
import withRouter from "../components/Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";
import { Badge } from "reactstrap";
import { useSelector } from "react-redux";

const SidebarContent = (props) => {
  const individualStore = useSelector((state) => state.individualSlice);
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const [dashboardData, setdashboardData] = useState([]);
  const { applied_jobs, interviews, offers } = dashboardData;

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (individualStore.data.JobsCount?.success === true) {
        setdashboardData(
          individualStore.data.JobsCount?.data?.dashboard_counts
        );
      }
    }
  }, [individualStore.data.JobsCount]);

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link
                to="/individualdashboard"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="home" />
                  <span>{props.t("Dashboard")}</span>
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="/individual-matchingjobs"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="briefcase" />
                  <span>{props.t("Matching Jobs")}</span>
                </div>
                {/* <Badge color="primary">21</Badge> */}
              </Link>
            </li>
            <li>
              <Link
                to="/individual-applied"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="send" />
                  <span>{props.t("Applied Jobs")}</span>
                </div>
                <Badge color="primary">
                  {applied_jobs?.total_applied_jobs}
                </Badge>
              </Link>
            </li>
            <li>
              <Link
                to="/individual-invitations"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="inbox" />
                  <span>{props.t("Invitations")}</span>
                </div>
                {/* <Badge color="primary">21</Badge> */}
              </Link>
            </li>
           {/* <li>
              <Link
                to="/individual-assessments"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="monitor" />
                  <span>{props.t("Assessments")}</span>
                </div>
                {/* <Badge color="primary">21</Badge> 
              </Link>
            </li>*/}
            {/* <li>
              <Link
                to="/individual-jobs"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="briefcase" />
                  <span>{props.t("All Jobs")}</span>
                </div>
                {/* <Badge color="primary">21</Badge> 
              </Link>
            </li> */}

            <li>
              <Link
                to="/individual-interviews"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="clipboard" />
                  <span>{props.t("Interviews")}</span>
                </div>
                <Badge color="primary">{interviews?.total_interviews}</Badge>
              </Link>
            </li>
            <li>
              <Link
                to="/individual-offers"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="inbox" />
                  <span>{props.t("Offers")}</span>
                </div>
                <Badge color="primary">{offers?.total_offers}</Badge>
              </Link>
            </li>
            <li>
              <Link
                to="/individual-contracts"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="pen-tool" />
                  <span>{props.t("Contracts")}</span>
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="/individual-timesheets"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="clipboard" />
                  <span>{props.t("Timesheets")}</span>
                </div>
                {/* <Badge color="secondary">Coming soon...</Badge> */}
              </Link>
            </li>

            {/* <li>
              <Link
                to="#"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="folder" />
                  <span>{props.t("Contracts")}</span>
                </div>
                <Badge color="secondary">Coming soon...</Badge>
              </Link>
            </li> */}
            {/* <li>
            <Link to="/individual-interviews" className="">
              <FeatherIcon icon="calendar" /> <span>{props.t("Interview")}</span>
            </Link>
          </li> */}
          </ul>
          {/* <div className="card sidebar-alert border-0 text-center mx-4 mb-0 mt-5">
          <div className="card-body">
            <img src={giftBox} alt="" />
            <div className="mt-4">
              <h5 className="alertcard-title font-size-16">
                Unlimited Access
              </h5>
              <p className="font-size-13">
                Upgrade your plan from a Free trial, to select ‘Business
                Plan’.
              </p>
              <a href="#!" className="btn btn-primary mt-2">
                Upgrade Now
              </a>
            </div>
          </div>
        </div> */}
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
