import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Iguid } from "../apiConfig/axiosDev";
import {
  sendGetRestRequest,
  sendPostRestRequestAppJson,
  sendPostRestRequest,
} from "../apiConfig/axiosInstance";
import { log_debug } from "../apiHelpers/logger";
import { useEffect, useState } from "react";
import { Guid } from "../apiConfig/axiosDev";
import { getIndividualGuid } from "../apiConfig/globalvariables";

const initialState = {
  data: {},
  status: "idle",
  loading: false,
  error: "",
};

//Individual Applied Jobs
export const getIndividualApplied = createAsyncThunk(
  "Individual/Applied",
  async ({ jobstatus, offset, skills, locations, filtervalue }) => {
    log_debug("individual slice guid", Iguid);
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/jobdescription/status/${jobstatus}?limit=10&offset=${offset}&locations=${locations}&skills=${skills}&searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//Individual Invited Jobs
export const getIndividualInvited = createAsyncThunk(
  "Individual/Invited",
  async ({ jobstatus, offset, skills, locations, filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/jobdescription/status/${jobstatus}?limit=10&offset=${offset}&locations=${locations}&skills=${skills}&searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//Individual Assessments
export const getIndividualAssessments = createAsyncThunk(
  "Individual/Assessments",
  async ({ filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/assessment?searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//Individual Jobs
export const getIndividualJobs = createAsyncThunk(
  "Individual/Jobs",
  async () => {
    const response = await sendGetRestRequest(
      `v1/individual/${getIndividualGuid() || Iguid}/jobdescription`,
    );
    return response.data;
  },
);

//Enum Locations
export const getIndividualLocations = createAsyncThunk(
  "Individual/Locations",
  async () => {
    const response = await sendGetRestRequest(`v1/enumlocation`);
    return response.data;
  },
);

//enum jobroles
export const getJobRoles = createAsyncThunk("Individual/Jobroles", async () => {
  const response = await sendGetRestRequest(`v1/enumjobrole?limit=1200`);
  return response.data;
});

//Individual Interviews
export const getIndividualInterviews = createAsyncThunk(
  "Individual/Interviews",
  async ({ filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/interview?searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//Individual Matching Jobs
export const getMatchingJobs = createAsyncThunk(
  "Individual/MatchingJobs",
  async ({ offset, skills, locations, filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/jobdescription?limit=10&offset=${offset}&locations=${locations}&skills=${skills}&searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//Individual dashboard counts
export const getJobsCount = createAsyncThunk(
  "Individual/JobsCount",
  async ({ fromDate, toDate }) => {
    log_debug("dashboard recieved api guid", getIndividualGuid());
    log_debug(" recieved from date", fromDate);
    log_debug(" recieved to date", toDate);
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/dashboard/count?from_date=${fromDate}&to_date=${toDate}`,
    );
    return response.data;
  },
);

//Individual Graph Count
export const getGraphCount = createAsyncThunk(
  "Individual/GraphCount",
  async ({ fromDate, toDate }) => {
    log_debug(" recieved from date", fromDate);
    log_debug(" recieved to date", toDate);
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/dashboard/graph?from_date=${fromDate}&to_date=${toDate}`,
    );
    return response.data;
  },
);

//Individual dashboard table applied jobs
export const getDashboardAppliedJobs = createAsyncThunk(
  "Individual/dashboard_applied_jobs",
  async ({ fromDate, toDate, offset }) => {
    log_debug(" recieved from date", fromDate);
    log_debug(" recieved to date", toDate);
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/jobdescription/status/applied?limit=10&offset=${offset}&from_date=${fromDate}&to_date=${toDate}`,
    );
    return response.data;
  },
);

//Individual dashboard table Interviews
export const getDashboardInterviews = createAsyncThunk(
  "Individual/dashboard_interviews",
  async ({ fromDate, toDate, offset }) => {
    log_debug("recieved iguid", getIndividualGuid());
    log_debug(" recieved from date", fromDate);
    log_debug(" recieved to date", toDate);
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/interview?limit=10&offset=${offset}&from_date=${fromDate}&to_date=${toDate}`,
    );
    return response.data;
  },
);

//Individual dashboard table Interviews
export const getDashboardOffers = createAsyncThunk(
  "Individual/dashboard_offers",
  async ({ fromDate, toDate, offset }) => {
    log_debug(" recieved from date", fromDate);
    log_debug(" recieved to date", toDate);
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/offer?limit=10&offset=${offset}&toDate=${toDate}`,
    );
    return response.data;
  },
);

// Individual Offers
export const getIndividualOffers = createAsyncThunk(
  "Individual/Offers",
  async ({ filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/offer?searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//Api to download certificate
export const downloadCertificate = createAsyncThunk(
  "Individual/DownloadCertificate",
  async (guid) => {
    const response = await sendGetRestRequest(
      `v1/assessment/certificate/${guid}`,
    );
    return response.data;
  },
);

export const IndividualCandidatureHistory = createAsyncThunk(
  "Individual/IndividualCandidatureHistory",
  async (ind_job_his) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getIndividualGuid() || Iguid
      }/individual_history/${ind_job_his}`,
    );
    return response.data;
  },
);

export const JobHistory = createAsyncThunk(
  "Individual/JobHistory",
  async (jobguid) => {
    const response = await sendGetRestRequest(
      `v1/jobdescription/${jobguid}/jobdescription_history`,
    );
    return response.data;
  },
);
//Individual Matching Jobs
export const getIndividualProfileDetails = createAsyncThunk(
  "Individualtest/Profile",
  async () => {
    const response = await sendGetRestRequest(
      `v1/individual/${getIndividualGuid() || Iguid}`,
    );
    return response.data;
  },
);
export const getIndividualAssmntScore = createAsyncThunk(
  "Individualtest/Assmnt/Score",
  async (assmntGuidScore) => {
    const response = await sendGetRestRequest(
      `v1/assessment/score/${assmntGuidScore}`,
    );
    return response.data;
  },
);

//update individual profile details
export const postIndividualProfileDetails = createAsyncThunk(
  "individual/postIndividualProfileDetails",
  async (fData) => {
    log_debug("recieved form data", fData);
    const response = await sendPostRestRequestAppJson(
      `v1/individual/${getIndividualGuid() || Iguid}`,
      fData,
    );
    return response.data;
  },
);

//Individual Matching Jobs
export const getCountries = createAsyncThunk("Country/Details", async () => {
  const response = await sendGetRestRequest(`v1/enumcountry`);
  return response.data;
});
//Individual Matching Jobs
export const getCityCountry = createAsyncThunk(
  "CountryCity/Details",
  async (countryName) => {
    const response = await sendGetRestRequest(
      `v1/enumcountry/country/${countryName}`,
    );
    return response.data;
  },
);

export const postIndividualMultipleJobApply = createAsyncThunk(
  "Individual/postMultipleJobs",
  async (jobGuids) => {
    const response = await sendPostRestRequestAppJson(
      `v1/individualjobmapping/${getIndividualGuid() || Iguid}/applyjobs`,
      jobGuids,
    );
    return response.data;
  },
);

export const getTIQscore = createAsyncThunk(
  "Individual/TIQscore",
  async (guid) => {
    const response = await sendGetRestRequest(`v1/assessment/score/${guid}`);
    return response.data;
  },
);

//Individual New Assessment
export const postAssessmentInvite = createAsyncThunk(
  "Individual/AssessmentInvitation",
  async ({ formdata }) => {
    const response = await sendPostRestRequestAppJson(
      `v1/individual/${getIndividualGuid() || Iguid}/send_invite`,
      formdata,
    );
    return response.data;
  },
);
//resume update
export const postIndividualResumeUpdate = createAsyncThunk(
  "IndividualResume/Update",
  async ({ resume_guid, addedData }) => {
    const response = await sendPostRestRequestAppJson(
      `v1/resume/${resume_guid}`,
      addedData,
    );
    return response.data;
  },
);

export const RoleBasedSkill = createAsyncThunk(
  "individual/RoleBasedSkill",
  async (role) => {
    const response = await sendGetRestRequest(
      `v1/individual/${getIndividualGuid() || Iguid}/role/${role}/get_skills`,
    );
    return response.data;
  },
);

export const postSendAssessmentLink = createAsyncThunk(
  "Individual/postSendAssessmentLink",
  async ({ data }) => {
    const response = await sendPostRestRequestAppJson(
      `v1/individual/${getIndividualGuid() || Iguid}/sendAssessment`,
      data,
    );
    return response.data;
  },
);

export const IndividualParseResumeapi = createAsyncThunk(
  "Individual/ParseResume",
  async (data) => {
    try {
      log_debug("addcandidateinfo", data);
      const response = await sendPostRestRequest(
        `v1/individual/parse/resume`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error("Error uploading candidate resume:", error);
      throw error;
    }
  },
);

export const newCandidateSignup = createAsyncThunk(
  "Individual/newCandidateSignup",
  async ({ data }) => {
    const response = await sendPostRestRequestAppJson(
      `v1/individual/create/individual`,
      data,
    );
    return response.data;
  },
);

const IndividualSlice = createSlice({
  name: "Individual",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIndividualApplied.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualApplied.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.applieddata = action.payload;
      })
      .addCase(getIndividualApplied.rejected, (state, action) => {
        state.status = "idle";
        state.data.applieddata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getIndividualInvited.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualInvited.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.invitedData = action.payload;
      })
      .addCase(getIndividualInvited.rejected, (state, action) => {
        state.status = "idle";
        state.data.invitedData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getIndividualAssessments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualAssessments.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.assessmentData = action.payload;
      })
      .addCase(getIndividualAssessments.rejected, (state, action) => {
        state.status = "idle";
        state.data.assessmentData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getIndividualJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualJobs.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.jobsData = action.payload;
      })
      .addCase(getIndividualJobs.rejected, (state, action) => {
        state.status = "idle";
        state.data.jobsData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getIndividualLocations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualLocations.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.locations = action.payload;
      })
      .addCase(getIndividualLocations.rejected, (state, action) => {
        state.status = "idle";
        state.data.locations = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getJobRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getJobRoles.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.jobroles = action.payload;
      })
      .addCase(getJobRoles.rejected, (state, action) => {
        state.status = "idle";
        state.data.jobroles = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getIndividualInterviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualInterviews.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.interviews = action.payload;
      })
      .addCase(getIndividualInterviews.rejected, (state, action) => {
        state.status = "idle";
        state.data.interviews = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getMatchingJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMatchingJobs.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.matchingjobs = action.payload;
      })
      .addCase(getMatchingJobs.rejected, (state, action) => {
        state.status = "idle";
        state.data.matchingjobs = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getJobsCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getJobsCount.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.JobsCount = action.payload;
      })
      .addCase(getJobsCount.rejected, (state, action) => {
        state.status = "idle";
        state.data.JobsCount = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getGraphCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getGraphCount.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.GraphCount = action.payload;
      })
      .addCase(getGraphCount.rejected, (state, action) => {
        state.status = "idle";
        state.data.GraphCount = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getDashboardAppliedJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashboardAppliedJobs.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardAppliedJobs = action.payload;
      })
      .addCase(getDashboardAppliedJobs.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardAppliedJobs = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getDashboardInterviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashboardInterviews.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardInterviews = action.payload;
      })
      .addCase(getDashboardInterviews.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardInterviews = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getDashboardOffers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashboardOffers.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardOffers = action.payload;
      })
      .addCase(getDashboardOffers.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardOffers = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getIndividualOffers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualOffers.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.Offers = action.payload;
      })
      .addCase(getIndividualOffers.rejected, (state, action) => {
        state.status = "idle";
        state.data.Offers = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(downloadCertificate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(downloadCertificate.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.certificate = action.payload;
      })
      .addCase(downloadCertificate.rejected, (state, action) => {
        state.status = "idle";
        state.data.certificate = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(IndividualCandidatureHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(IndividualCandidatureHistory.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.IndividualCandidatureHistory = action.payload;
      })
      .addCase(IndividualCandidatureHistory.rejected, (state, action) => {
        state.status = "idle";
        state.data.IndividualCandidatureHistory = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(JobHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(JobHistory.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.JobHistory = action.payload;
      })
      .addCase(JobHistory.rejected, (state, action) => {
        state.status = "idle";
        state.data.JobHistory = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getTIQscore.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTIQscore.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.TIQscore = action.payload;
      })
      .addCase(getTIQscore.rejected, (state, action) => {
        state.status = "idle";
        state.data.TIQscore = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getIndividualProfileDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualProfileDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.ProfileDetails = action.payload;
      })
      .addCase(getIndividualProfileDetails.rejected, (state, action) => {
        state.status = "idle";
        state.data.ProfileDetails = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      .addCase(postIndividualProfileDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postIndividualProfileDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.ProfileDetails = action.payload;
      })
      .addCase(postIndividualProfileDetails.rejected, (state, action) => {
        state.status = "idle";
        state.data.ProfileDetails = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      .addCase(getCityCountry.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCityCountry.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.countryCities = action.payload;
      })
      .addCase(getCityCountry.rejected, (state, action) => {
        state.status = "idle";
        state.data.countryCities = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      .addCase(getCountries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.Countries = action.payload;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.status = "idle";
        state.data.Countries = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      .addCase(getIndividualAssmntScore.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualAssmntScore.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.IndiAssmntScore = action.payload;
      })
      .addCase(getIndividualAssmntScore.rejected, (state, action) => {
        state.status = "idle";
        state.data.IndiAssmntScore = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(postAssessmentInvite.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postAssessmentInvite.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.assessmentinvite = action.payload;
      })
      .addCase(postAssessmentInvite.rejected, (state, action) => {
        state.status = "idle";
        state.data.assessmentinvite = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      .addCase(postIndividualResumeUpdate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postIndividualResumeUpdate.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.individualResume = action.payload;
      })
      .addCase(postIndividualResumeUpdate.rejected, (state, action) => {
        state.status = "idle";
        state.data.individualResume = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(RoleBasedSkill.pending, (state) => {
        state.status = "loading";
      })
      .addCase(RoleBasedSkill.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.RoleBasedSkill = action.payload;
      })
      .addCase(RoleBasedSkill.rejected, (state, action) => {
        state.status = "idle";
        state.data.RoleBasedSkill = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(postSendAssessmentLink.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postSendAssessmentLink.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.postSendAssessmentLink = action.payload;
      })
      .addCase(postSendAssessmentLink.rejected, (state, action) => {
        state.status = "idle";
        state.data.postSendAssessmentLink = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      });
  },
});

export default IndividualSlice.reducer;
