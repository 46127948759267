import React, { useState, useEffect } from "react";
// import { Container } from 'reactstrap'
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
  Button,
} from "reactstrap";
import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import dayjs from "dayjs";
// import DataTable from "../customComponents/DataTable";
import { lineStyle, dropdownOptions, dropdownOptions2 } from "../data/Data";
import DropDownHeader from "../customComponents/DropDownHeader";
import { useSelector, useDispatch } from "react-redux";
import {
    PartnerAppliedJobs,
  Shortlistedjobsdata,
  addCandidatesLocation,
  addCandidatesSkills,
  getFilterJobsByStatus,
} from "./PartnerSlice";
import CustomTable from "../customComponents/reactTable/CustomTable";
import "../../src/assets/css/styleCompanyBench.css";
import DetailsCanvas from "../customComponents/offcanvas/CanvasJobdescription";
import { Link } from "react-router-dom";
import { log_debug } from "../apiHelpers/logger";
import CanvasPartnerJobDetails from "../customComponents/offcanvas/CanvasPartnerJobDetails";
import { Tags } from "../pages/Contacts/ContactList/contactlistCol";
import NodataFoundTable from "../customComponents/utilities/NodataFoundTable";
import Customloading from "../customComponents/Customloading/Customloading";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb";
import { dateyyyymmdd_tohtml } from "../helpers/date";
import JobsBadge from "../customComponents/utilities/JobsBadge";

function AppliedJobs() {
  //PAGINATION
  const [currentPage, setCurrentPage] = useState(0);

  const [AppliedJobs, setAppliedJobs] = useState([]);
  const [AppliedJobscount, setAppliedJobscount] = useState(0);



  // const count = Shortlistjobs.length;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PartnerAppliedJobs({currentPage:currentPage})).then((res) => {
        log_debug("appliedjobs4324",res.payload);
        const data = res?.payload?.data?.data;
        setAppliedJobs(data);
        setAppliedJobscount(res?.payload?.data?.totalDocuments)
    })
  },[currentPage])

  const columns = [
    {
      Header: "Job Id",
      accessor: "jobId",
      Cell: ({ row }) => (
            <div className="">
              {row.original?.jobId ? row.original?.jobId : "-"}
            </div>
          ),
    },
    {
      Header: "Job Title",
      accessor: "jobName",
      Cell: ({ row }) => (
        <div className="">
          {row.original?.jobName ? row.original?.jobName : "-"}
        </div>
      ),
    //   Cell: ({ row }) => (
    //     <div className="">
    //       {row.original.location.length >= 1
    //         ? row.original.location.join(", ")
    //         : "-"}
    //     </div>
    //   ),
    },

    {
      Header: "Candidate Name",
      accessor: "candidateName",
      Cell: ({ row }) => (
        <div className="">
          {row.original?.candidateName ? row.original?.candidateName : "-"}
        </div>
      ),
    //   Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Applied Date",
      accessor: "appliedDate",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Submission Status",
      accessor: "status",
      Cell: ({ value }) => <JobsBadge status={value === "shortlisted" ? "applied" : value} />
    //   Cell: ({ row }) => (
    //     <div className="">
    //       {row.original?.minExperience ? row.original?.minExperience : 0}
    //     </div>
    //   ),
    },
    {
      Header: "Remarks",
      accessor: "submissionNotes",
      Cell: ({ row }) => {
        const notes = row.original?.submissionNotes || "-"; // Get the value or fallback to "-"
        const truncatedNotes = notes.length > 18 ? `${notes.slice(0, 18)}...` : notes; // Truncate if needed
        return <span>{truncatedNotes}</span>;
      },
    }
  ];
  const breadcrumbValues = ["Applied jobs"];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <CustomBreadcrumb values={breadcrumbValues} />
          <div className="col-lg-12">
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <Row>
                      <div className="col-12 col-lg-12 col-md-6">
                        <Col xl={12} lg={12} md={6}>
                          <CardTitle className="page-title text-muted text-right">
                            <span>Total count : </span>
                            <strong>
                            {AppliedJobscount}
                            </strong>
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                    {/* </CardBody> */}
                    {/* </Card> */}
                  </div>
                  <div>
                    <Card>
                      <CardBody>
                        <CustomTable
                          totalCount={AppliedJobscount}
                          columns={columns}
                          data={AppliedJobs}
                          hideCheckbox={true}
                          currentPage={currentPage}
                          onPageChange={handlePageChange}
                        />
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default AppliedJobs;
