import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { BsThreeDotsVertical } from "react-icons/bs";
import joblogo from "../../assets/images/companyBenchImage/jobgray1.jpg";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Offcanvas,
  TabContent,
  OffcanvasHeader,
  OffcanvasBody,
  TabPane,
  UncontrolledDropdown,
  Button,
  Badge,
} from "reactstrap";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaRegQuestionCircle } from "react-icons/fa";
import CustomTable from "../../customComponents/reactTable/CustomTable";
import {
  CandidatureHistory,
  IndividualDetail,
  JobDetail,
  interviewHistory,
} from "../PartnerSlice";
import TimelineComponent from "../../customComponents/timelineComponents/TimeLineComponent";
import { log_debug } from "../../apiHelpers/logger";
import CanvasPartnerJobDetails from "../../customComponents/offcanvas/CanvasPartnerJobDetails";
import FunnelProgressBar from "../../components/Common/FunnelProgressBar";

export function InterviewDetailsOffcanvas({
  isOpen,
  toggle,
  onClose,
  content,
  data,
}) {
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  log_debug("datadatadata", data);
  const [candidatureHistory, setCandidatureHistory] = useState([]);
  const [individualDetail, setIndividualDetail] = useState({});
  const [jobDetail, setJobDetail] = useState({});
  const [isJobCanvas, setIsJobCanvas] = useState(false);
  const [headerTab, setHeaderTab] = useState("1");
  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };
  const offcanvasStyle = {
    width: "50%", // You can set a default width or use the provided width
  };
  const columns = [
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          // onClick={()=>{candidateName(row.original.guid)}}
        >
          {row.values.title}
        </Link>
      ),
    },
    {
      Header: "Summary",
      accessor: "summary",
      width: 100,
    },
    {
      Header: "Locations",
      accessor: "location",
      width: 200,
    },
    {
      Header: "Skills",
      accessor: "skills",
      width: 100,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: () => {
        return (
          // <button onClick={handleButtonClick} type="button" className="btn btn-primary">
          //   View
          // </button>
          <BsThreeDotsVertical className="mx-3" />
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(interviewHistory(data.indv_guid));
  }, []);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore?.data?.IndividualDetail?.success === true) {
        setIndividualDetail(
          partnerStore?.data?.IndividualDetail?.data?.individual,
        );
      }
      if (partnerStore?.data?.CandidatureHistory?.success === true) {
        setCandidatureHistory(
          partnerStore?.data?.CandidatureHistory?.data?.individual_history,
        );
      }

      if (partnerStore?.data?.JobDetail?.success === true) {
        setJobDetail(partnerStore?.data?.JobDetail?.data?.jobdescription);
      }
    }
  }, [partnerStore]);

  useEffect(() => {
    dispatch(IndividualDetail(data?.indv_guid));
    dispatch(CandidatureHistory(data?.individualjobmapping_guid));
    dispatch(JobDetail(data?.job_guid));
  }, []);

  const getInitials = (fullName) => {
    return fullName
      ?.split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  };

  const handleJob = (e) => {
    e.preventDefault();
    // setJobGuid(guid);
    setIsJobCanvas(true);
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom " toggle={toggle}>
          <h5 className="text-primary">
            Interview Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Show Details of selected
              interview.
            </span>
          </h5>
          <FunnelProgressBar candidatureHistory={candidatureHistory} interviewStatus={data} />
        </OffcanvasHeader>

        <OffcanvasBody>
          <Row className="p-2">
            <div className="col-md-6">
              <div className="col-sm order-2 order-sm-1 card h-100 px-3 p-2">
                <div className="d-flex align-items-start mt-3 mt-sm-0">
                  <div className="flex-shrink-0">
                    <div className="avatar-xl mx-auto mb-0 me-3 mt-1">
                      <span
                        className="avatar-title bg-primary text-white font-size-24 p-3 display-3 m-0 rounded-circle"
                        style={{ width: "65px", height: "65px" }}
                      >
                        {getInitials(data?.candidate)}
                      </span>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div>
                      <h5 className="font-size-16 ">
                        {data.candidate}
                        <Badge className={`bg-${data?.status.includes("Scheduled") || data?.status.includes("Passed") ?"success":"danger"}-subtle p-1 text-${data?.status.includes("Scheduled") || data?.status.includes("Passed")?"success":"danger"} font-size-12 float-end`}>
                          {data?.status}
                        </Badge>
                      </h5>
                      <div className="d-flex flex-wrap align-items-start text-muted font-size-13">
                        <span><i className="mdi mdi-phone me-1 text-primary align-middle w-100"></i>{individualDetail.contact_number ? individualDetail.contact_number : ''}</span>
                        <div className="w-100">
                          <i className="mdi mdi-email me-1 text-primary align-middle"></i>
                          {individualDetail?.email}
                        </div>
                      </div>

                      <div className="d-flex flex-wrap align-items-start text-muted font-size-13 mt-2">
                        <div>
                          <span>
                            {individualDetail &&
                              individualDetail?.skills?.map((skills, index) => (
                                <Link
                                  key={index}
                                  to="#"
                                  className="badge bg-primary rounded-pill px-3 p-1 mx-1"
                                >
                                  {skills}
                                </Link>
                              ))}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-sm order-2 order-sm-1 card h-100 px-3 p-2">
                <div className="d-flex align-items-start">
                  <div className="flex-shrink-0">
                    <div className="avatar-xl me-3">
                      <img
                        src={joblogo}
                        alt=""
                        className="img-fluid rounded-circle d-block "
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="col-sm-auto order-1 order-sm-2">
                      <Badge className="bg-success-subtle p-1 text-success font-size-12 float-end">
                        {jobDetail?.status}
                      </Badge>
                    </div>
                    <div>
                      <Link
                        to="#"
                        onClick={(e) => {
                          handleJob(e);
                        }}
                        className="font-size-16 mb-2"
                      >
                        {jobDetail?.title}
                      </Link>

                      <h5 className="text-muted font-size-13 mt-1 mb-1">
                        <i className="mdi mdi-map-marker me-1 text-primary align-middle"></i>{" "}
                        {jobDetail?.location}
                      </h5>

                      <div className="d-flex flex-wrap align-items-start gap-lg-3 font-size-14 mt-2">
                        <div>
                          <span>
                            {jobDetail?.skills?.map((skill, index) => (
                              <Link
                                key={index}
                                to="#"
                                className="badge bg-primary rounded-pill px-3 p-1 mx-1"
                                // className="badge bg-primary  mx-1 p-1"
                              >
                                {skill}
                              </Link>
                            ))}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13 mt-1">
                        <div>
                          <i className="mdi mdi-account-details me-1 text-primary align-middle"></i>
                          <span>
                            {jobDetail?.description?.substring(0, 80)}...
                            {/* <Link to="#">See more</Link> */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <TIQcard/> */}
            </div>
            <div className="mt-0 mb-0 text-muted">
              <hr />
            </div>
          </Row>

          {candidatureHistory && candidatureHistory.length > 0 && (
            <TimelineComponent
              historydata={candidatureHistory}
              type={"candidatureHistory"}
            />
          )}
        </OffcanvasBody>
      </Offcanvas>
      {isJobCanvas && (
        <CanvasPartnerJobDetails
          Alljobsstatus={true}
          width={50}
          placement="end"
          isOpen={isJobCanvas}
          toggle={() => setIsJobCanvas(!isJobCanvas)}
          // onclose={closeJobCanvas}
          data={jobDetail}
        />
      )}
    </>
  );
}

export default InterviewDetailsOffcanvas;
